<template>
  <div class="panorama-index">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item>全景项目</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-bar">
      <div class="left">
        <el-form :inline="true" :model="query" class="search-form-inline">
          <el-form-item>
            <el-input v-model="query.title" placeholder="请输入场景名称" clearable @clear="getDataList"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getDataList">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="right">
        <el-button type="primary" @click="addVisible = true">添加项目</el-button>
      </div>
    </div>
    <div class="page-content">
      <el-table :data="dataList" border style="width: 100%" max-height="500">
        <el-table-column prop="id" label="#" width="80"></el-table-column>
        <el-table-column prop="m_id" label="混合ID" width="120"></el-table-column>
        <el-table-column prop="title" label="项目名称"></el-table-column>
        <el-table-column fixed="right" label="操作" width="240">
          <template slot-scope="scope">
            <el-button size="small" v-clipboard:copy="'https://' + domain + '/#/' + scope.row.m_id" v-clipboard:success="onCopy" v-clipboard:error="onError">复制链接</el-button>
            <el-button size="small" type="success" @click="editItem(scope.row.m_id)">编辑</el-button>
            <el-button type="danger" size="small" @click="delItem(scope.row.m_id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.currentPage" :page-sizes="[20, 50, 100]" :page-size="query.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="query.total"></el-pagination>
      </div>
    </div>
    <!-- 添加项目弹窗 -->
    <el-dialog title="添加项目" :visible.sync="addVisible" width="30%" :before-close="addClose">
      <el-form :model="addForm" :rules="addRules" ref="addRef" class="add-form" label-position="top">
        <el-form-item label="项目标题" prop="title">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="项目描述" prop="desc">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入内容" v-model="addForm.desc"></el-input>
        </el-form-item>
        <el-form-item label="项目缩略图" prop="img">
          <upload :url="addForm.img" :filed="'img'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="镜头缩略图" prop="camera_img">
          <upload :url="addForm.camera_img" :filed="'camera_img'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="初始镜头类型" prop="type">
          <el-radio-group v-model="addForm.type">
            <el-radio :label="1">已上传视频文件</el-radio>
            <el-radio :label="2">直播链接</el-radio>
            <el-radio :label="3">站外视频链接</el-radio>
            <el-radio :label="4">已上传全景图</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="请选择已上传视频文件" prop="online_video" v-if="addForm.type === 1">
          <upload :url="addForm.url" :filed="'url'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="请填写直播链接" prop="url" v-if="addForm.type === 2">
          <el-input v-model="addForm.url"></el-input>
        </el-form-item>
        <el-form-item label="请填写站外视频链接" prop="url" v-if="addForm.type === 3">
          <el-input v-model="addForm.url"></el-input>
        </el-form-item>
        <el-form-item label="请选择已上传全景图" prop="url" v-if="addForm.type === 4">
          <upload :url="addForm.url" :filed="'url'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item style="margin-top: 40px;">
          <el-button type="primary" @click="addSubmit('addRef')">新增项目</el-button>
          <el-button @click="cancellAdd">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '../../common/Upload'
export default {
  name: 'Index',
  components: {
    Upload
  },
  data () {
    return {
      domain: '',
      query: {
        title: '',
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      message: '复制链接',
      dataList: [],
      addForm: {
        title: '',
        desc: '',
        img: '',
        type: 1,
        camera_img: '',
        url: ''
      },
      addRules: {
        title: [
          { required: true, message: '请输入项目标题', trigger: 'blur' },
          { min: 3, max: 30, message: '长度在 3 到 30 个字符', trigger: 'blur' }
        ],
        img: [
          { required: true, message: '请上传缩略图', trigger: 'blur' }
        ]
      },
      // 添加弹窗
      addVisible: false
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    // 获取已添加全景项目列表
    async getDataList () {
      const { data: res } = await this.$http.get('panorama-project-list', { params: this.query })
      if (res.status === 200) {
        this.dataList = res.data.list
        this.query.total = res.data.total
        this.query.currentPage = res.data.currentPage
        this.query.pageSize = res.data.pageSize
        this.domain = res.data.domain
      } else {
        this.$message.error(res.msg)
      }
    },
    // 关闭弹窗
    addClose (done) {
      this.$refs.addRef.resetFields()
      done()
    },
    // 添加项目
    addSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!this.addForm.url) {
            this.$message.error('请检查表单是否填写完整')
            return false
          }
          const { data: res } = await this.$http.post('panorama-project/0', this.addForm)
          if (res.status === 200) {
            await this.getDataList()
            this.$refs.addRef.resetFields()
            this.addVisible = false
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 取消添加项目
    cancellAdd () {
      this.$refs.addRef.resetFields()
      this.addVisible = false
    },
    // 删除项目
    async delItem (mid) {
      const { data: res } = await this.$http.delete('panorama-project', { params: { mid: mid } })
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getDataList()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 进入项目编辑页面
    editItem (mid) {
      const routeUrl = this.$router.resolve({
        path: '/panorama/' + mid + '/base-set'
      })
      window.open(routeUrl.href, '_blank')
    },
    // 复制链接
    onCopy (e) {
      this.$message.success('复制成功')
    },
    onError (e) {
      this.$message.error('复制失败')
    },
    // 更改每页显示数量
    handleSizeChange (val) {
      this.query.pageSize = val
      this.getDataList()
    },
    // 更改页码
    handleCurrentChange (val) {
      this.query.currentPage = val
      this.getDataList()
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.addForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.addForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.addForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.addForm[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
.search-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-form-inline .el-form-item{
  margin-bottom: 0;
}
.add-form{
  padding: 0 40px;
  text-align: left;
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0;
}
.el-form-item{
  margin-bottom: 8px;
}
.el-descriptions{
  padding: 0 40px;
}
.el-radio-group .el-radio{
  margin-bottom: 10px;
}
</style>
